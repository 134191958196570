@import "~@flaticon/flaticon-uicons/css/all/all";

:root {
	--color-primary: #dd3030;
	--color-primary-container: #ffdad6;
	--color-primary-container-hover: #fceae8;
	--color-on-primary-container: #3b0908;

	--color-player: #b68a26;
	--color-background-player: #ffdea3;

	--color-title: #534341;
	--color-text: #231918;
	--color-secondary-text: #534341;
	--color-text-on-dark: #f1dedc;

	--color-background-light: #ffffff;
	--color-background-dark: #1a1110;

	--color-outline: #857371;
	--color-shadow: #00000029;
	--color-card: #fff8f7;

	--color-error: #d5351b;
	--color-disabled: #6d696a8a;
}

@font-face {
	font-family: "Lucida Blackletter Regular";
	font-style: normal;
	font-weight: normal;
	src: local("lucida_blackletter_regular"),
		url("./font/lucida_blackletter_regular.woff") format("woff");
}

@font-face {
	font-family: "Icomoon";
	src: local("icomoon"),
		url("./font/icomoon.eot?15qfbm#iefix") format("embedded-opentype"),
		url("./font/icomoon.ttf?15qfbm") format("truetype"),
		url("./font/icomoon.woff?15qfbm") format("woff"),
		url("./font/icomoon.svg?15qfbm#icomoon") format("svg");
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	padding: 0;
	font-family: "Segoe UI", Arial, sans-serif;
	color: var(--color-text);
	background-color: var(--color-background-light);
}

/* header base styles */
h1 {
	color: var(--color-primary);
	font-size: 32px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
}

/* other base styles */
p,
ul,
ol {
	color: var(--color-text);
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 20px;
}

ul,
ol {
	padding-inline-start: 24px;
}

/* links styles */
a:link {
	color: #dd3030;
	background-color: transparent;
	text-decoration: none;
}
a:visited {
	color: #71a9f7;
	background-color: transparent;
	text-decoration: none;
}
a:hover {
	color: #9f1515;
	background-color: transparent;
	text-decoration: underline;
}
a:active {
	color: #6b5ca5;
	background-color: transparent;
	text-decoration: underline;
}

/* Other component */
.divider {
	width: calc(100% - 48px);
	height: 1px;
	background-color: var(--color-outline);
	margin: 0 24px 6px;
}
